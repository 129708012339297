
  import { defineComponent } from "vue";
  
  export default defineComponent({
    props: {
      icon: {
        type: Object,
        required: true,
      },
      message: {
        type: String,
        required: true,
      },
      subtext: {
        type: String,
        required: true,
      },
      link: {
        type: String,
        required: true,
      },
      linkText: {
        type: String,
        required: true,
      },
    },
  });
  