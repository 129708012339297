<template>
    <div>
      <Error
        :icon="ErrorIcon"
        message="You do not have access to this page"
        subtext="Contact your admin or our support team to access this page."
        link="/dashboard"
        linkText="Go To Mekari Developer Center"
      />
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import Error from "@/views/errors/Error.vue";
  import ErrorIcon from "@/assets/images/error/403.svg";
  
  export default defineComponent({
    components: {
      Error,
    },
    data() {
      return {
        ErrorIcon,
      };
    },
  });
  </script>