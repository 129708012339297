
  import { defineComponent } from "vue";
  import Error from "@/views/errors/Error.vue";
  import ErrorIcon from "@/assets/images/error/403.svg";
  
  export default defineComponent({
    components: {
      Error,
    },
    data() {
      return {
        ErrorIcon,
      };
    },
  });
  